@import "./assets/scss/colors";

html, body, #root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: $BACKGROUND;
  font-size: 14px !important;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

.nav-active {
  background-color: $PRIMARY !important;
  color: $WHITE !important;
}

.nav-active svg {
  color: $WHITE !important;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.leaflet-right {
  display: none !important;
}

.div-map-text {
  text-align: center !important;
  background-color: #00A29B !important;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.MuiExpansionPanelSummary-content .Mui-expanded {
  background-color: black !important;
}

.div-marker-icon {
  background: none;
  border: none
}

.leaflet-popup-content {
  width: unset !important;
}

.marker-cluster {
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marker-cluster-group {
  width: 38px !important;
  height: 38px !important;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  color: $WHITE;
}

.marker-cluster-group-founded {
  //background-color: $SUCCESS !important;
  background-image: radial-gradient(
                  circle,
                  $SUCCESS,
                  rgba(255, 255, 255, 0.1)
  );
}

.marker-cluster-group-unfounded {
  background-image: radial-gradient(
                  circle,
                  $ERROR,
                  rgba(255, 255, 255, 0.1)
  );
}

.leaflet-popup-tip-container {
  display: none !important;
}


.echarts-for-react {
  & > div:nth-child(1) {
    width: 100% !important;

    & > canvas {
      width: 100% !important;
    }
  }
}
